/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  Button,
  Checkbox,
  DatePicker,
  FormSuccess,
  Header,
  LayoutUI,
  LeadText,
  Stack,
  Textarea,
  TextField,
  TextStyle
} from '../../../ui-kit';

import ROUTES, { API_ROUTES } from '../../../utils/routes';
import { humanizeCamelCase } from '../../../utils/string';
import FormError from '../../../utils/formError';

import Layout from '../../../components/layout';
import Navbar from '../../../components/navbar/navbar';
import MobileNavbar from '../../../components/mobile-navbar/mobile-navbar';

import * as styles from './EventsForms.module.scss';

export default class SpeakerSeriesForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      organization: '',
      email: '',
      phone: '',
      eventName: '',
      location: '',
      eventDate: null,
      audienceSize: '',
      eventWebsite: '',
      willProvideHonorium: true,
      comments: '',

      formErrors: undefined,
      formSubmitted: false,
      formSuccess: undefined
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.makeInput = this.makeInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  makeInput(name, label, isRequired = true) {
    const formattedLabel = label == null ? humanizeCamelCase(name) : label;

    return (
      <Stack.Item fill>
        <TextField
          label={formattedLabel}
          name={name}
          onChange={this.handleInputChange}
          value={this.state[name]}
          isRequired={isRequired}
        />
      </Stack.Item>
    );
  }

  handleInputChange(event) {
    const { target } = event;
    const { name, type } = target;
    const value = type === 'checkbox' ? target.checked : target.value;

    this.setState({
      [name]: value
    });
  }

  handleSubmit() {
    const {
      firstName,
      lastName,
      organization,
      email,
      phone,
      eventName,
      location,
      eventDate,
      audienceSize,
      willProvideHonorium,
      comments
    } = this.state;

    const { path, method } = API_ROUTES.speakerSeriesRequestCreate();

    this.setState({ formSubmitted: true });

    fetch(path, {
      method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        speaker_series_request: {
          first_name: firstName,
          last_name: lastName,
          organization,
          email,
          phone,
          event_name: eventName,
          event_location: location,
          event_date: eventDate,
          event_audience_size: audienceSize,
          event_provides_honorium: willProvideHonorium,
          comments
        }
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }

        throw new FormError(response);
      })
      .then(json => {
        this.setState({
          formSuccess: (
            <FormSuccess
              name={json.first_name}
              successPhrase="Thank you for your speaking request! We'll be reaching out shortly."
              href={ROUTES.liveEvents()}
              buttonText="Go Back"
              color="orange"
            />
          )
        });
      })
      .catch(error => {
        this.setState({
          formErrors: error.formErrors,
          formSubmitted: false
        });
      });
  }

  render() {
    return this.state.formSuccess ? (
      this.state.formSuccess
    ) : (
      <Layout>
        <Navbar />
        <MobileNavbar />
        <div>
          <LayoutUI narrow>
            <div className={styles.Heading}>
              <Stack vertical>
                <TextStyle color="orange">
                  <Header>Speaker Series Request Form</Header>
                </TextStyle>
                <LeadText>
                  Invite Randy Taran to your event and inspire your audience to
                  bring lasting change! Fill out this form and we will contact
                  you as soon as possible.
                </LeadText>
              </Stack>
            </div>
          </LayoutUI>

          <LayoutUI narrow>
            <Stack vertical spacing="loose">
              <Stack>
                {this.makeInput('firstName')}
                {this.makeInput('lastName')}
              </Stack>

              {this.makeInput('organization')}

              <Stack>
                {this.makeInput('email')}
                {this.makeInput('phone')}
              </Stack>
            </Stack>
          </LayoutUI>
          <LayoutUI narrow>
            <Stack vertical spacing="loose">
              <Header>Speaking Event</Header>

              {this.makeInput('eventName')}
              {this.makeInput('location')}

              <Stack>
                <Stack.Item fill>
                  <DatePicker
                    date={this.state.eventDate}
                    label="Event date"
                    onDateChange={date => this.setState({ eventDate: date })}
                    isRequired
                  />
                </Stack.Item>
                {this.makeInput('audienceSize', 'Approx. audience size')}
              </Stack>

              <Checkbox
                checked={this.state.willProvideHonorium}
                label="I will be providing an honorarium"
                name="willProvideHonorium"
                onChange={this.handleInputChange}
              />

              <Textarea
                label="Comments"
                name="comments"
                onChange={this.handleInputChange}
                value={this.state.comments}
              />

              <Stack distribution="center">
                <Button
                  color="orange"
                  onClick={this.handleSubmit}
                  disabled={this.state.formSubmitted}
                >
                  Submit
                </Button>
              </Stack>
            </Stack>
          </LayoutUI>
        </div>
      </Layout>
    );
  }
}
